// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-receta-template-js": () => import("./../src/components/RecetaTemplate.js" /* webpackChunkName: "component---src-components-receta-template-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-galeria-js": () => import("./../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-home-2-js": () => import("./../src/pages/home2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-index-2-js": () => import("./../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../src/pages/index3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-pagequeries-js": () => import("./../src/pages/pagequeries.js" /* webpackChunkName: "component---src-pages-pagequeries-js" */),
  "component---src-pages-queries-js": () => import("./../src/pages/queries.js" /* webpackChunkName: "component---src-pages-queries-js" */)
}

